import "./App.css";
import Header from "./components/header";
// import Services from './components/services';
import Testimonials from "./components/testimonials";
import Contact from "./components/contact";
import Footer from "./components/footer";
import FAQ from "./components/faq";
import About from "./components/about";
import Value from "./components/value";
import ProductLeft from "./components/product-left";
import ProductRight from "./components/product-right";
import searchScreenshot from "./assets/searchScreenshot.png";
import creatorScreenshot from "./assets/creatorScreenshot.png";
import filterSummaryScreenshot from "./assets/filterSummaryScreenshot.png";
import {
  CloudArrowUpIcon,
  ChartBarSquareIcon,
  ClockIcon,
  LockClosedIcon,
  ServerIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import ReactGA from "react-ga4";
import { useEffect } from "react";
const features = [
  {
    name: "Multi Platform",
    description: "Easily view and analyze all social platforms in one place",
    icon: UserGroupIcon,
  },
  {
    name: "Statistics Over Time",
    description: "Track growth over time and, visualize performance",
    icon: ClockIcon,
  },
  {
    name: "Visualize Content",
    description:
      "See exactly what a creator is talking about, brands they've worked with, and more",
    icon: ChartBarSquareIcon,
  },
];

function App() {
  return (
    <div>
      <Header></Header>
      <ProductRight
        subHeading="Simplify"
        heading="Creator Discovery"
        mainText="Follower minimums, and average view minimums are just the beginning for us."
        buttonText="Learn More"
        image={searchScreenshot}
        showTestimony={false}
        link="https://calendly.com/leadhuntprosoftware/30min"
      ></ProductRight>
      <ProductLeft
        subHeading="Leading"
        heading="Creator Statistics"
        mainText="Keyword use, management status, growth statistics, links in bio and so much more."
        buttonText="Learn More"
        image={creatorScreenshot}
        showTestimony={false}
        features={features}
      ></ProductLeft>
      <ProductRight
        subHeading="Unlock"
        heading="Enterprise Level Scale"
        mainText="Find thousands of unique creators in only 10 minutes. Our clients super charge their management agencies, marketing campaigns or creator first products at a fraction of the cost of the big guys."
        buttonText="Learn More"
        image={filterSummaryScreenshot}
        showTestimony={false}
        link="https://calendly.com/leadhuntprosoftware/30min"
      ></ProductRight>
      <Value></Value>
      {/* <Testimonials></Testimonials> */}
      <About></About>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
