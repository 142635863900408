import { CheckIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

const features = [
  {
    name: "Spending Hours Searching Socials",
    description:
      "If you are still spending hours scrolling through social media search results to find creators, save your eyesight.",
    check: false,
  },
  {
    name: "LeadhuntPro Realtime Scaled Search",
    description:
      "Use our scaled search platform to collect hundreds of creators in just 10 minutes.",
    check: true,
  },
  {
    name: "Giant, Irrelevant & Stale Lead Lists",
    description:
      "You know... it's that guy you have to send you giant excel sheets of social media accounts with 5 people that are actually worth reaching out to.",
    check: false,
  },
  {
    name: "LeadhuntPro Advanced Filters & Scoring",
    description:
      "Follower minimums, and average view minimums are just the beginning for us. We will run similarity analysis, check for keyword use, management status, growth statistics, links in bio and so much more.",
    check: true,
  },
  {
    name: "Enterprise Software Pricing & Contracts",
    description:
      "Annual lock-ins, expensive retainers, thousands per month per user.",
    check: false,
  },
  {
    name: "LeadhuntPro Simple Pricing",
    description:
      "Our plans start as low as a few hundred bucks per month for unlimited access.",
    check: true,
  },
];

export default function Value() {
  return (
    <div className="bg-white py-24 sm:py-32" id="Services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              Everything you need
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              All-in-one platform
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              When it comes to creator campaigns and agency growth, we've got
              you covered.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  {feature.check ? (
                    <CheckIcon
                      className="absolute top-1 left-0 h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <XMarkIcon
                      className="absolute top-1 left-0 h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
